import { CommonMudule } from '@/store/modules/common'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import router from '@/router/router'

NProgress.configure({ showSpinner: false })
const whiteList = ['/login']

router.beforeEach(async(to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()

  // Determine whether the user has logged in
  if (UserModule.token && UserModule.userInfo.id) {
    if (to.path === '/login') {
      // If is logged in, redirect to the home page
      next()
      NProgress.done()
    } else {
      if (to.meta.offType && CommonMudule.authArr.includes(to.meta.offType)) {
        if (_.path === '/analys/index') {
          next()
        } else {
          Message({
            message: '没有权限',
            type: 'error',
            duration: 5 * 1000
          })

          next({ path: _.path })
          NProgress.done()
        }
      } else {
        if (!CommonMudule.customerId) {
          if (to.path !== '/userSelect') {
            Message({
              message: '请选择客户',
              type: 'error',
              duration: 5 * 1000
            })
            next({ path: '/userSelect' })
            NProgress.done()
          } else {
            next()
            NProgress.done()
          }
        } else {
          next()
          NProgress.done()
        }
      }
      // Check whether the user has obtained his permission roles
      // if (UserModule.roles.length === 0) {
      //   try {
      //     // Get user info, including roles
      //     await UserModule.GetUserInfo()
      //     // Set the replace: true, so the navigation will not leave a history record
      //     next({ ...to, replace: true })
      //   } catch (err) {
      //     // Remove token and redirect to login page
      //     UserModule.ResetToken()
      //     Message.error(err || 'Has Error')
      //     next(`/login?redirect=${to.path}`)
      //     NProgress.done()
      //   }
      // } else {
      //   next()
      // }
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // In the free login whitelist, go directly
      next()
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  const link: any = document.querySelector('.linkTag')

  if (!link.href.endsWith('.png') || !link.href.endsWith('.ico')) {
    link.rel = 'shortcut icon'
    link.type = 'image/x-icon'
    link.href = CommonMudule.imgSource.webicon[0].url
  }

  // set page title
  document.title = to.meta.title
})
