



















import pathToRegexp from 'path-to-regexp'
import { Component, Vue, Watch } from 'vue-property-decorator'
// @ts-ignore
import { RouteRecord, Route } from 'vue-router'

@Component({
  name: 'Breadcrumb'
})
export default class extends Vue {
  private breadcrumbs: RouteRecord[] = []

  @Watch('$route')
  private onRouteChange(route: Route) {
    if (route.path.startsWith('/redirect/')) {
      return
    }
    this.getBreadcrumb()
  }

  created() {
    this.getBreadcrumb()
  }

  private getBreadcrumb() {
    const matched = this.$route.matched.filter((item: any) => {
      return item.meta && item.meta.title
    })
    const p = this.$route.params.id
    if (JSON.stringify(this.$route.params) !== '{}' && p === 'analy') {
      matched[0].meta.title = '财富体检'
      matched[1].meta.title = '资产相关'
    }
    if (this.$route.path === '/portfolio/group1') {
      matched[0].meta.title = '投资组合规划'
    }
    const first = matched[0]
    // if (!this.isDashboard(first)) {
    //   matched = [
    //     { path: '/dashboard', meta: { title: 'Dashboard' }
    //     } as RouteRecord
    //   ].concat(matched)
    // }
    // @ts-ignore
    this.breadcrumbs = matched.filter((item) => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false
    })
  }

  private isDashboard(route: RouteRecord) {
    const name = route && route.meta && route.meta.title
    return name === 'Dashboard'
  }

  private pathCompile(path: string) {
    const { params } = this.$route
    const toPath = pathToRegexp.compile(path)
    return toPath(params)
  }

  private handleLink(item: any) {
    const { redirect, path } = item
    if (redirect) {
      this.$router.push(redirect)
      return
    }
    this.$router.push(this.pathCompile(path))
  }
}
