/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'admin_back': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M13.5 8.789l2.031 1.172a1 1 0 01.5.866v2.346a1 1 0 01-.5.866L13.5 15.21a1 1 0 01-1 0l-2.031-1.172a1 1 0 01-.5-.866v-2.346a1 1 0 01.5-.866L12.5 8.79a1 1 0 011 0zM13 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"/><path pid="1" d="M13.5.5a1 1 0 011 1v6.794l-1-.577a1 1 0 00-.88-.059l-.12.059-2.87 1.657a1 1 0 00-.5.866v3.498a1 1 0 00.5.866l1.553.896H1.5a1 1 0 01-1-1v-13a1 1 0 011-1h12zM7 8.5H4a.5.5 0 00-.09.992L4 9.5h3a.5.5 0 000-1zm3-3H4a.5.5 0 00-.09.992L4 6.5h6a.5.5 0 000-1z"/></g>'
  }
})
