
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import SidebarItem from './SidebarItem.vue'
import variables from '@/styles/_variables.scss'

@Component({
  name: 'SideBar',
  components: {
    SidebarItem
  }
})

export default class extends Vue {
  indexPath = ''
  get sidebar() {
    return AppModule.sidebar
  }

  get activeMenu() {
    const route = this.$route
    const { meta, path } = route
    let activeMenu
    if (path === '/portfolio/portkyc/analy') {
      activeMenu = '/analys'
    } else {
      activeMenu = meta.activeMenu
    }
    // if set path, the sidebar will highlight the path you set
    return activeMenu
  }

  get routes() {
    return (this.$router as any).options.routes
  }

  get variables() {
    return variables
  }

  get isCollapse() {
    return !this.sidebar.opened
  }
}
