/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'child_plan': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<g transform="translate(2 1)" _fill="#FFF" fill-rule="evenodd"><circle pid="0" cx="5.143" cy="2.857" r="2.857"/><circle pid="1" cx="13.143" cy="9.714" r="1.714"/><path pid="2" d="M5.094 5.714c1.622 0 3.11.905 3.856 2.346.576 1.114 1.012 2.039 1.307 2.776.204 1.164-.723 1.367-1.172 1.367-.3 0-.767-.487-1.402-1.46v6.743a.8.8 0 01-.8.8H6.27a.8.8 0 01-.8-.8v-4.322h-.71l.015 4.319a.8.8 0 01-.8.803h-.672a.8.8 0 01-.8-.8v-6.65c-.651 1.241-1.303 1.697-1.955 1.367-.556-.281-.617-1.105-.498-1.367.43-.945.86-1.865 1.29-2.762a4.162 4.162 0 013.753-2.36z"/><path pid="3" d="M11.962 11.752h.965c.895 0 1.713.507 2.11 1.31.265.535.578.979.94 1.332.127.627-.291.617-.57.617-.187 0-.477-.262-.871-.786v3.46a.602.602 0 11-1.204 0v-2.157h-.755l.008 2.123a.632.632 0 11-1.267 0v-4.72c-1.45-.748-2.175-1.37-2.175-1.868 0-.4.564-.911.636-.745a2.378 2.378 0 002.183 1.434z"/></g>'
  }
})
