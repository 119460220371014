import { Message } from 'element-ui'
let messageInstance = null
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
};
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})

/**
 * 延迟执行
 * @param {延迟时间} time
 */
function delayExec(time) {
  let timer = null
  let msgs = []
  return function(ops) {
    const strOps = typeof ops === 'string' ? ops : JSON.stringify(ops)
    const args = arguments
    const that = this

    if (msgs.length > 0 && msgs.indexOf(strOps) === -1) {
      that.$message.apply(that, args)
      msgs.push(strOps)
      timer = null
      return
    }

    msgs.push(strOps)
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(function() {
      that.$message.apply(that, args)
      msgs = []
    }, time)
  }
}
const _message = delayExec(300)
const message = resetMessage
export {
  message,
  _message
}
