import Vue, { DirectiveOptions } from 'vue'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'
import App from './App.vue'
import store from '@/store'
import router from '@/router/router'
import * as filters from '@/utils/filter'
import * as directives from '@/utils/directive'
import { validator } from '@/utils/valid'
import echarts from 'echarts'
import 'vue-tsx-support/enable-check'
import '@/styles/element-variables.scss'
import '@/styles/index.scss'
import '@/styles/base.scss'
import '@/icons/components'
import '@/permission'
import 'normalize.css'
import VueLazyload from 'vue-lazyload'
import { message } from '@/components/elementCom/resetMessage'
import {
  prefetchApps,
  addGlobalUncaughtErrorHandler,
  removeGlobalUncaughtErrorHandler
} from 'qiankun'

import { isLoadPage, micorApps } from '@/utils/tools'

Vue.prototype.$validator = validator
Vue.prototype.$echarts = echarts

Vue.use(ElementUI, {
  size: 'small'
})

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})
Vue.prototype.$message = message

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})

Object.keys(directives).forEach((key) => {
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key])
})

Object.keys(filters).forEach((key) => {
  Vue.filter(key, (filters as { [key: string]: Function })[key])
})

Vue.config.productionTip = false

function micorErrorHandle(event: Event) {
  console.log('micorErrorHandle app error', event)
}

new Vue({
  router,
  store,
  beforeDestroy() {
    if (isLoadPage()) {
      removeGlobalUncaughtErrorHandler(micorErrorHandle)
    }
  },
  render: (h) => h(App)
}).$mount('#app')

if (isLoadPage()) {
  // @ts-ignore
  addGlobalUncaughtErrorHandler(micorErrorHandle)
  prefetchApps(micorApps)
}
