/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'intro': {
    width: 26,
    height: 28,
    viewBox: '0 0 26 28',
    data: '<g transform="translate(1 1)" _stroke="#2179FF" stroke-width="2" _fill="none" fill-rule="evenodd"><path pid="0" d="M9.051 26H2a2 2 0 01-2-2V2a2 2 0 012-2h18a2 2 0 012 2v9.032" stroke-linecap="round"/><circle pid="1" cx="17" cy="19" r="5"/><path pid="2" stroke-linecap="round" d="M21 23l2.35 2.299M4 6h13.892M4 12h7M4 18h4"/></g>'
  }
})
