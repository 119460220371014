import { riskTypeFind } from '@/api/portfolio'
import variables from '@/styles/_variables.scss'
import echarts from 'echarts'
import { comdify } from '@/utils/validate'

export const seriesCircleColor = ['#FB767B', '#1E2F97', '#6196FE', '#2179ff', '#7DD6F6', '#A1C9A4', '#E2D83B', '#5D7599', '#F7775D', '#FCA700', '#6568F5']
export const seriesPieColor = ['#2179ff', '#F7775D', '#FCA700', '#6568F5', '#1E2F97', '#6196FE', '#7DD6F6', '#A1C9A4', '#E2D83B', '#5D7599']
export const seriesBarColor = ['#2179ff', '#48CBCB', '#6AA5FF', '#4DB7F3']
export const seriesGaugeColor = {
  low: '#1BA6E7',
  normal: '#7ED3F6',
  high: '#F15F50'
}
export const colors = {
  blue: '#2179FF',
  red: '#F5222D'
}

export const barOption = {

  color: seriesBarColor,
  grid: {
    left: '6%',
    bottom: '9%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      axisTick: { show: true, alignWithLabel: true },
      axisLabel: { // 重点在这一块，其余可以忽略
        interval: 0, // 这个一定要有，别忘记了
        rotate: 15,
        textStyle: {
          color: '#333',
          fontSize: 12
        }
      },
      axisLine: {
        show: true,
        interval: 0,
        lineStyle: {
          color: '#fff'
        }
      },
      data: []
    }
  ],
  label: {
    show: true,
    position: 'top'
  },
  yAxis: {
    type: 'value',
    splitLine: {
      show: false
    }
  },
  tooltip: {
  },
  series: [{
    type: 'bar',
    // 柱状图形显示的颜色
    data: []
  }]
}

export const barOption2 = {
  grid: {
    left: '6%',
    bottom: '9%',
    containLabel: true
  },
  color: seriesBarColor,
  xAxis: [
    {
      type: 'category',
      data: [''],
      axisTick: { show: false, alignWithLabel: true },
      axisLabel: {
        interval: 0,
        textStyle: {
          color: '#333',
          fontSize: 12
        }
      },
    }
  ],
  label: {
    show: true,
    position: 'top',
    formatter: (param: any) => {
      return comdify(String(param.value))
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      show: false
    }
  },
  tooltip: {
  },
  series: [{
    type: 'bar',
    barMaxWidth: 80,
    barGap: '150%',
    data: [{
      name: '预设投资总金额',
      value: 0,
      itemStyle: {
        color: '#2179FF',
      }

    },
    {
      name: '本计划的预计未来价值',
      value: 0,
      itemStyle: {
        color: '#48CBCB',
      }
    }]

  }]
}

export const barOption3 = {
  grid: {
    left: '6%',
    bottom: '9%',
    containLabel: true
  },
  dataset: {
    source: [
      ['product', '', ''],
    ]
  },
  legend: {},
  color: [variables.chartColor, '#48CBCB'],
  xAxis: { type: 'category' },
  label: {
    show: true,
    position: 'top',
    formatter: (params:any) => {
      return params.value[params.encode.y[0]] + '%'
    }
  },
  yAxis: {
    axisLabel: {
      formatter: {

      }
    },
    splitLine: {
      show: false
    }
  },
  tooltip: {
  },
  series: [
    { type: 'bar' },
    { type: 'bar' },
  ]
}

export const riskTypeObj = {
  '保守型': 1,
  '适度保守型': 2,
  '稳健型': 3,
  '适度积极型': 4,
  '积极型': 5
}

export const riskTypeObj2 = {
  1: '保守型',
  2: '适度保守型',
  3: '稳健型',
  4: '适度积极型',
  5: '积极型'
}

export const riskTypeOjb3 = {
  Conservative: '保守型',
  ConservativeModerately: '适度保守型',
  Steady: '稳健型',
  PositiveModerately: '适度积极型',
  Positive: '积极型'
}

const basseSeries = {
  data: [],
  type: 'scatter',
  symbolSize: function(data: any) {
    return Math.sqrt(data[2]) / 20
  },
  emphasis: {
    label: {
      show: true,
      formatter: function(param: any) {
        return param.data[3]
      },
      position: 'top'
    }
  },
  itemStyle: {
    shadowBlur: 10,
    shadowColor: 'rgba(120, 36, 50, 0.5)',
    shadowOffsetY: 5,
    // @ts-ignore
    color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
      offset: 0,
      color: 'rgb(251, 118, 123)'
    }, {
      offset: 1,
      color: 'rgb(204, 46, 72)'
    }])
  }
}
export const scatterOption = {
  grid: {
    top: '15%',
    right: '15%',
  },
  color: 'rgb(129, 227, 238)',
  title: {
  },
  xAxis: {
    name: '风险',
    nameTextStyle: {
      fontSize: 22
    },
    min: 0,
    max: 5,
    splitNumber: 2,
    maxInterval: 2.5,
    splitLine: {
      show: true
    }
  },
  yAxis: {
    name: '流动性',
    nameTextStyle: {
      fontSize: 22
    },
    min: 0,
    max: 5,
    maxInterval: 2.5,
    splitNumber: 2,
    splitLine: {
      show: true
    },
    scale: true
  },
  series: [{
    data: [],
    type: 'scatter',
    symbolSize: function(data:any) {
      return Math.sqrt(data[2]) / 20
    },
    emphasis: {
      label: {
        show: true,
        formatter: function(param: any) {
          return param.data[3]
        },
        position: 'top'
      }
    },
    itemStyle: {
      shadowBlur: 10,
      shadowColor: 'rgb(129, 227, 238)',
      shadowOffsetY: 5,
      // @ts-ignore
      color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
        offset: 0,
        color: '#6196FE'
      }, {
        offset: 1,
        color: '#6196FE'
      }])
    }
  },
  ]
}

export const gaugeOption = {
  tooltip: {
    formatter: '{a} <br/>{b} : {c}%'
  },
  toolbox: {
  },
  series: [
    {
      name: '业务指标',
      type: 'gauge',
      detail: { formatter: '{value}%' },
      data: [{ value: 0, name: '' }],
      axisLine: {
        lineStyle: {
          color: [],
          width: 10
        }
      }
    }
  ]
}

export const lineOption = {
  color: ['#2179ff'],
  legend: {
    left: 'center',
    data: ['tokyo']
  },
  grid: {
    left: '10%',
    right: '2%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      axisTick: { show: true, alignWithLabel: true },
      axisLabel: { // 重点在这一块，其余可以忽略
        textStyle: {
          color: '#333',
          fontSize: 12
        }
      },
      axisLine: {
        onZero: true,
        show: true,
        interval: 0,
        lineStyle: {
          color: '#333'
        }
      },
      data: []
    }
  ],
  yAxis: {
    axisLine: { show: true },
    axisTick: { show: true },
    type: 'value',
    name: '',
    splitLine: {
      show: false,
      lineStyle: {
        type: 'dashed'
      }
    }
  },
  tooltip: {
  },
  series: [{
    type: 'line',
    symbol: 'circle', // 折线点设置为实心点
    symbolSize: 10, // 折线点的大小
    itemStyle: {
      normal: {
        color: ['#2179ff'], // 折线点的颜色
        lineStyle: {
          color: ['#2179ff']// 折线的颜色
        }
      }
    },
    data: []
  }]
}
