/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'customer': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<g _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M6.5 8a6 6 0 016 6c0 1.298-12 1.298-12 0a6 6 0 016-6zm0-6a3 3 0 110 6 3 3 0 010-6z"/><path pid="1" d="M9.732 3.018c1.665-.233 4.101 1.793 1.99 4.292.338.11 3.427 1.04 3.778 5.05 0 .58-1.113 1.282-2.148 1.282 0-.244.123-4.3-4-5.912.159-.172 2.165-2.352.446-4.612-.048-.07-.048-.07-.066-.1z"/></g>'
  }
})
