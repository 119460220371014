/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'finnace_suggest': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<g transform="translate(1 .718)" _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M13 0a2 2 0 012 2v8.188a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h11zm-1.16 4.182a.5.5 0 00-.707-.037L7.984 6.98 6.03 5.024a.496.496 0 00-.303-.144l-.023-.003a.499.499 0 00-.369.128L3.198 6.928a.5.5 0 00.669.743l1.786-1.608L7.51 7.919c.015.015.03.029.047.041l.036.045a.5.5 0 00.706.037l3.503-3.154a.5.5 0 00.037-.706z"/><rect pid="1" x="3.75" y="14.063" width="7.5" height="1" rx=".5"/></g>'
  }
})
