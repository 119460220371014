/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.316 2.269c.532.21 2.592 1.241 2.592 3.836 0 .59.378.684.911.934.534.25 2.828 1.24 2.11 4.145-.48 1.936-2.01 2.79-4.592 2.562H3.273C1.69 13.345.635 12.4.108 10.912c-.545-3.245 1.116-4.564 1.885-4.958.513-.263.958-.423 1.336-.48.455-.055.802-.37 1.04-.943.359-.861 1.282-1.895 2.46-2.308 1.176-.414 2.94-.163 3.487.046zm-2.283 3.07a.6.6 0 00-.6.6v3.399l-.907-.907a.6.6 0 10-.849.848l1.873 1.873a.6.6 0 00.129.098l-.051-.044.064.053a.6.6 0 00.682 0l.083-.07 1.873-1.873.07-.083a.6.6 0 00-.07-.765l-.083-.07a.6.6 0 00-.765.07l-.85.849.001-3.377a.6.6 0 00-.6-.6z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
