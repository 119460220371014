
import { DirectiveOptions } from 'vue'

export const enterNumber: DirectiveOptions = {
  inserted(el) {
    el.addEventListener('keypress', function(e) {
      e = e || window.event
      const charcode =
          typeof e.charCode === 'number' ? e.charCode : e.keyCode
      const re = /\d/
      if (
        !re.test(String.fromCharCode(charcode)) &&
          charcode > 9 &&
          !e.ctrlKey
      ) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
}

export const canEnterDecimal: DirectiveOptions = {
  inserted(el) {
    el.addEventListener('keypress', function(e) {
      e = e || window.event
      const charcode =
        typeof e.charCode === 'number' ? e.charCode : e.keyCode
      const re = /\d/
      const targetEl = e.target as HTMLInputElement
      if (charcode === 46) {
        if (targetEl.value.includes('.')) {
          e.preventDefault()
        }
      } else if (
        !re.test(String.fromCharCode(charcode)) &&
        charcode > 9 &&
        !e.ctrlKey
      ) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
}
