






















































































































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { ElForm } from 'element-ui/types/form'
import { validatePass } from '@/utils/tools'
import { ElInput } from 'element-ui/types/input'
import md5 from 'js-md5'
import { changPwd } from '@/api/users'
import { UserModule } from '../../../store/modules/user'

interface InfoForm{
  pwd: string | null
  rePwd: string | null
  oldPassword: string | null
}

@Component({
  name: '',
  components: {
    moduleHeader
  }
})
export default class extends Vue {
  passwordType2 = 'password'
  passwordType1 = 'password'
  passwordType3 = 'password'
  infoForm: InfoForm = {
    pwd: null,
    rePwd: null,
    oldPassword: null
  }

  validatePass = validatePass
  showPwd() {
    this.passwordType1 = this.passwordType1 === 'password' ? '' : 'password'
    this.$nextTick(() => {
      (this.$refs.pwd1 as ElInput).focus()
    })
  }

  showPassword2() {
    this.passwordType2 = this.passwordType2 === 'password' ? '' : 'password'
    this.$nextTick(() => {
      (this.$refs.pwd2 as ElInput).focus()
    })
  }

  showPassword3() {
    this.passwordType3 = this.passwordType3 === 'password' ? '' : 'password'
    this.$nextTick(() => {
      (this.$refs.pwd3 as ElInput).focus()
    })
  }

  onBack() {
    this.$router.back()
  }

  confirm() {
    (this.$refs.resetForm as ElForm).validate(async valid => {
      if (valid) {
        if ((this.infoForm.pwd as string).trim() === (this.infoForm.rePwd as string).trim()) {
        // eslint-disable-next-line no-useless-escape
          var reg = /^[a-zA-Z0-9?~!,\.#&@$%^*]{5,20}$/
          if (!reg.test(this.infoForm.pwd as string)) {
            this.$message({
              type: 'error',
              message: '请输入6~20位英文字符'
            })
            return false
          } else {
            const { oldPassword, pwd } = this.infoForm
            if (oldPassword === pwd) {
              this.$message({
                type: 'error',
                message: '新密码和原密码不能相同'
              })
              return
            }
            if (oldPassword && pwd) {
              const res = await changPwd({
                oldPassword: md5(oldPassword.trim()),
                password: md5(pwd.trim())
              })
              if (res.statusCode === 0 && res.data) {
                this.$message({
                  type: 'success',
                  message: '修改成功, 请重新登录'
                })
                UserModule.ResetToken()
                this.$router.replace({
                  path: '/login'
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.errorMsg
                })
              }
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: '两次输入的密码结果不一致，请重新输入'
          })
        }
      }
    })
  }
}
