import { LibraryItem, AuthItem } from '@/types/common'
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep } from 'lodash'
import { seriesBarColor } from '@/store/modules/option'
import { rmbFilter } from '@/utils/filter'

export const formatResOption = (data: LibraryItem[]) => {
  const temp = []
  for (const v of data) {
    temp.push({
      label: v.dicName,
      value: v.id
    })
  }
  return temp
}

export const formatRes = (data: any[], key?: string, value?: string) => {
  if (!data) {
    return []
  }
  key = key || 'first'
  value = value || 'second'
  const temp = []
  for (const v of data) {
    temp.push({
      name: v[key],
      value: v[value]
    })
  }
  return cloneDeep(temp)
}

export const formaChartRes = (data: any[], key?: string, value?: string) => {
  if (!data) {
    return []
  }
  key = key || 'first'
  value = value || 'second'
  const temp: any[] = []
  data.forEach((v: object, index) => {
    temp.push({
      // @ts-ignore
      name: v[key],
      // @ts-ignore
      value: v[value],
      itemStyle: {
        color: seriesBarColor[index]
      }
    })
  })

  return cloneDeep(temp)
}

export const formaChartOneRes = (data: any[], key?: string, value?: string) => {
  if (!data) {
    return []
  }
  key = key || 'first'
  value = value || 'second'
  const temp: any[] = []
  data.forEach((v: object, index) => {
    temp.push({
      // @ts-ignore
      name: v[key],
      // @ts-ignore
      value: v[value],
      itemStyle: {
        color: seriesBarColor[0]
      }
    })
  })

  return cloneDeep(temp)
}
export const formatTbData = (v: any) => {
  const tableData: object[] = []
  let tableHeader: string[] = []
  tableHeader = v.colNames
  v.assetAnalysisItemTable.forEach((itemRow: any) => {
    const obj: { [propName: string]: any } = {}

    obj[v.colNames[0]] = itemRow.itemName
    itemRow.items.forEach((ele: any) => {
      obj[ele.colName] = rmbFilter(ele.colFieldValue)
    })
    tableData.push(obj)
  })
  return { tableData, tableHeader }
}

export const validateMax = (rule: any, value: any, callback: Function) => {
  const res = /^[0-9]+.?[0-9]*$/
  if (res.test(value)) {
    if (value > 10) {
      return callback(new Error('假定通胀率最大值10%'))
    } else {
      return callback()
    }
  } else {
    callback(new Error('请输入数字'))
  }
}

export const validPointTwo = (rule: any, value: any, callback: Function) => {
  const res = /^[0-9]+.?[0-9]*$/
  if (res.test(value)) {
    if (value > 3.5) {
      return callback(new Error('假定通胀率最大值3.5%'))
    } else {
      return callback()
    }
  }
}
// 折合美元
export const calcRMBDollars = (n: number): number =>
  Number((n / CommonMudule.rmbDollarRate).toFixed(2))

export const toRMBDollars = (n: number): number =>
  Number((CommonMudule.rmbDollarRate / n).toFixed(2))

/**
 * 预期未来收益
 * 单笔本息 + 定投本息 = 预期未来收益
 */

/**
 * 单笔本息
 * @param x : 单笔投资 - 单笔投资钱
 * @param r : 组合收益率
 * @param n : 投资年限
 */
export const spi = (x: number, r: number, n: number) => {
  return x * Math.pow(1 + r, n)
}

/**
 * 定投本息
 *  @param y : 定投部分 - 单笔投资钱
 *  @param f : r / 定投期
 *  @param n : 投资年限
 *  @param c : 投资频次 月、季度、年
 *  @param r : 组合收益率
 */
export const ppi = (y: number, r: number, n: number, c: number) => {
  const f = (r / 12) * c
  return (y * (Math.pow(1 + f, n * (12 / c)) - 1)) / f
}
/**
 * 预期未来收益
 */
export const efn = (x: number, y: number, r: number, n: number, c: number) => {
  const result = Number((spi(x, r, n) + ppi(y, r, n, c)).toFixed(2))
  if (isNaN(result)) {
    return 0
  } else {
    return result
  }
}
// -----------------------------------------------------------------
/**
 * 扣除通胀后未来收益 =  扣除通胀率后的 单笔本息cspi + 扣除通胀率后的 定投本息cppi
 *  @param t : 通胀率
 */

/**
 *   扣除通胀率后的 单笔本息cspi
 */
export const cspi = (x: number, r: number, n: number, t: number) => {
  return x * Math.pow(1 + r - t, n)
}
/**
 *   扣除通胀率后的 定投本息cppi
 */
// (y * (Math.pow((1 + f), n * (12 / c)) - 1)) / f
export const cppi = (y: number, r: number, n: number, c: number, t: number) => {
  const a = ((r - t) / 12) * c
  return (y * (Math.pow(1 + a, n * (12 / c)) - 1)) / a
}
/**
 *  扣除通胀后未来收益
 */
export const ctefn = (
  x: number,
  y: number,
  r: number,
  n: number,
  c: number,
  t: number
) => {
  // console.log('x', x)
  // console.log('y', y)
  // console.log('ctefn r', r)
  // console.log('n', n)
  // console.log('c', c)
  // console.log('t', t)
  const result = Number((cspi(x, r, n, t) + cppi(y, r, n, c, t)).toFixed(2))
  if (isNaN(result)) {
    return 0
  } else {
    return result
  }
}
/**
 *  token失效
 */
export const TokenNull = (x: any) => {
  if (x === 1) {
    window.location.href = process.env.VUE_APP_HY_LOGIN || ''
  }
}

export const floatReg = (n: string | number) => {
  n = String(n)
  const r = /^(-?\d+)(\.\d+)?$/
  return r.test(n)
}
/**
 *  加法
 */
export const accAdd = (arg1: number, arg2: number) => {
  let r1
  let r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))
  return (arg1 * m + arg2 * m) / m
}

/**
 *  减法
 */
export const accSub = (arg1: number, arg2: number) => {
  let r1
  let r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))
  const n = r1 >= r2 ? r1 : r2
  return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
}

/**
 *  乘法
 */
export const accDiv = (arg1: number, arg2: number) => {
  let c = 0
  const d = arg1.toString()
  const e = arg2.toString()
  try {
    c += d.split('.')[1].length
  } catch (f) {
    // s
  }
  try {
    c += e.split('.')[1].length
  } catch (f) {
    // s
  }
  return (
    (Number(d.replace('.', '')) * Number(e.replace('.', ''))) / Math.pow(10, c)
  )
}

/**
 *  除法
 */
export const accMul = (arg1: number, arg2: number) => {
  let t1 = 0
  let t2 = 0
  try {
    t1 = arg1.toString().split('.')[1].length
  } catch (e) {
    // s
  }
  try {
    t2 = arg2.toString().split('.')[1].length
  } catch (e) {
    // s
  }
  const r1 = Number(arg1.toString().replace('.', ''))
  const r2 = Number(arg2.toString().replace('.', ''))
  return (r1 / r2) * Math.pow(10, t2 - t1)
}

export const qs = <T>(obj: T): string => {
  let s = ''
  for (const key in obj) {
    if (obj[key]) {
      s += `${key}=${obj[key]}&`
    }
  }
  if (s.endsWith('&')) {
    s = s.slice(0, -1)
  }
  return s
}

export const getSessionAuth = (str: string) => {
  const s = JSON.parse(sessionStorage.getItem('authObj') as string)
  if (s && JSON.stringify(s) !== '{}') {
    return s[str]
  }
  return false
}

export const validatePass = (
  rule: string,
  value: string,
  callback: Function
) => {
  // eslint-disable-next-line no-useless-escape
  var reg = /^[a-zA-Z0-9?~!,\.#&@$%^*]{5,20}$/

  if (!reg.test(value)) {
    return callback(new Error('请输入6~20位英文字符'))
  } else {
    return callback()
  }
}

export const foramtParam = (params: any) => {
  let paramsStr = ''
  for (const i in params) {
    if (params[i] || params[i] === 0) {
      paramsStr += `${i}=${params[i]}&`
    }
  }
  if (paramsStr.endsWith('&')) {
    paramsStr = paramsStr.slice(0, -1)
  }
  return paramsStr
}

/**
 * 手机号正则
 */
export const isCellPhone = (val: string) => {
  if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
    return false
  } else {
    return true
  }
}

export const checkphone = (rule: any, value: any, callback: Function) => {
  // let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!isCellPhone(value)) {
    // 引入methods中封装的检查手机格式的方法
    callback(new Error('请输入正确的手机号!'))
  } else {
    callback()
  }
}

/**
 * 邮箱格式
 */
export const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/

export const checkEmail = (rule: any, value: any, callback: Function) => {
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else if (!mailReg.test(value)) {
    // 引入methods中封装的检查手机格式的方法
    callback(new Error('请输入正确的邮箱!'))
  } else {
    callback()
  }
}

export const isLoadPage = function isLoadPage() {
  const pathname = window.location.pathname
  if (pathname.includes('/print') || pathname.includes('/preview')) {
    return false
  }
  if (process.env.NODE_ENV !== 'production') return false
  return true
}

export const micorApps = [
  {
    activeRule: '/manage/manage/dashbi',
    name: 'sub-app-dashbi',
    entry:
      process.env.NODE_ENV === 'production'
        ? window.location.origin + '/amc/amc-dashbi/'
        : '//localhost:3000',
    container: '#sub-dashbi',
    props: { routerBase: '/amc/amc-dashbi/' }
    // routerBase: '/amc/market-radar'
  }
]
