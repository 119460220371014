/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'login_personnel_admin': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<g transform="translate(1 2)" _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M6 6a6 6 0 016 6c0 1.298-12 1.298-12 0a6 6 0 016-6zm0-6a3 3 0 110 6 3 3 0 010-6z"/><rect pid="1" x="9.5" y=".5" width="5" height="1" rx=".5"/><rect pid="2" x="10.5" y="3.5" width="4" height="1" rx=".5"/><rect pid="3" x="11.5" y="6.5" width="3" height="1" rx=".5"/></g>'
  }
})
