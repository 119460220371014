/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'invest_plan': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<g _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M7.5 16.718c4.142 0 6.927-3.058 7.34-6.92H6.905V1.715C2.924 2.272 0 5.075 0 9.218a7.5 7.5 0 007.5 7.5z"/><path pid="1" d="M16 8.718a8 8 0 00-8-8v7.999l8 .001z"/></g>'
  }
})
