import request from '@/utils/request'
import { userTable, LoginParams, UserDetailParams, ChangePWD, LoginRes, UserInfo, UserInfoParams } from '@/types/user'
import { qs, foramtParam } from '@/utils/tools'

/**
 * 理财师信息
 */
export const fetchUserDetail = (params: UserDetailParams) =>
  request({
    url: `/blackcat/${params.userId}/user/detail`,
    method: 'get',
    params
  })

export const getUserInfo = (data: any) =>
  request({
    url: '/users/info',
    method: 'post',
    data
  })

export const login = (data: LoginParams) => {
  const p = qs(data)
  return request({
    url: `/blackcat/login?${p}`,
    method: 'post',
    headers: {
      contentType: 'application/json'
    }
  })
}

export const logout = () =>
  request({
    url: '/blackcat/logout',
    method: 'post'
  })
  // 客户查询
export const custormSearch = (params: userTable) =>
  request({
    url: '/blackcat/customer/page',
    method: 'get',
    params
  })
// 保存客户
export const custormSave = (data: userTable) =>
  request({
    url: '/blackcat/customer/save',
    method: 'post',
    data
  })

/**
 * 修改密码
 * @param data:ChangePWD
 */
export const changPwd = (data: ChangePWD) =>
  request({
    url: '/nb/account/password/update',
    method: 'post',
    data
  })

/**
 *
 * 头像 上传地址
 */
export const changeAvatarUrl = '/aap/api/v1/nb/storage/file/upload'
/**
 * 修改客户信息
 */
export const changeUserInfo = (data: LoginRes | UserInfo) =>
  request({
    url: `/nb/account/user/basicinfo/update`,
    method: 'post',
    data
  })

/**
 * 添加用户
 */
export const addUser = (data : UserInfo) =>
  request({
    url: `/blackcat/nb/user/save`,
    method: 'post',
    data
  })

/**
 * 用户列表
 */
export const fetchUserList = (params: UserInfoParams) => {
  let p: {[propName: string]: any} = {}
  p = Object.assign({}, params)
  p.page = p.page - 1
  return request({
    url: `/blackcat/nb/user/findall?${foramtParam(p)}`,
    method: 'get',
  })
}
/**
 * 用户启用禁用
 */
export const userStatusHandle = (data: any) => {
  return request({
    url: `/blackcat/nb/user/changeflag`,
    method: 'post',
    data
  })
}

/**
 * 修改列表客户信息
 */
export const updateListUserInfo = (data: LoginRes | UserInfo) =>
  request({
    url: `/blackcat/nb/user/update`,
    method: 'post',
    data
  })

/**
 * 是否需要发送手机验证码验证
 */
export const hasPhoneCodeValid = () =>
  request({
    url: `/blackcat/tenant/config`,
    method: 'get',
  })

/**
 * 发送手机验证码
 */
export const sendPhoneCode = (code: string) =>
  request({
    url: `/blackcat/sms/send?mobile=${code}`,
    method: 'get',
  })
