import { UserModule } from '@/store/modules/user'
import axios from 'axios'
import { Message } from 'element-ui'
import { AppModule } from '@/store/modules/app'
import { CustomAxiosConfig } from '@/types/axios'
import Cookies from 'js-cookie'
import router from '@/router/router'
import { TokenNull } from '@/utils/tools'

const service = axios.create({
  baseURL: '/aap/api/v1',
  timeout: 150000
})

// Request interceptors
service.interceptors.request.use(
  (config: CustomAxiosConfig) => {
    if (!(typeof config.loading === 'boolean' && config.loading === false)) {
      AppModule.COMMIT_LOADING(true)
    }
    // Add X-Access-Token header to every request, you can add other custom headers here
    // if (UserModule.token) {
    //   // config.headers['X-Access-Token'] = UserModule.token
    // }
    const token = window.sessionStorage.getItem('token') || Cookies.get('nbToken')
    // if (token) {
    //   config.headers['Authorization'] = 'bearer ' + token
    // } else {
    //   config.headers['Authorization'] = 'bearer D7C7B90FF69248DC9FA0565549EF4C8D'
    // }
    config.headers['Authorization'] = 'bearer ' + token
    return config
  },
  (error) => {
    AppModule.COMMIT_LOADING(false)
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    // 本地环境需要注释、生产环境需要放开
    // TokenNull(response.data.statusCode)
    const resData = response.data
    const { statusCode } = resData
    AppModule.COMMIT_LOADING(false)
    if (statusCode !== 0) {
      if (!String(statusCode).startsWith('9')) {
        if (document.getElementsByClassName('el-message').length < 1) {
          Message({
            message: resData.errorMsg,
            type: 'error',
            duration: 5 * 1000,
          })
        }
        if (statusCode === 3) {
          UserModule.ResetToken()
          router.replace({
            path: '/login'
          })
        }
        Promise.reject((new Error(resData.errorMsg || 'Error')))
      }
    }
    return resData
  },
  (error) => {
    AppModule.COMMIT_LOADING(false)
    console.log('error', error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
