




































































import path from 'path'
import { Component, Prop, Vue } from 'vue-property-decorator'
// @ts-ignore
import { Route, RouteConfig } from 'vue-router'
import { isExternal } from '@/utils/validate'
import SidebarItemLink from './SidebarItemLink.vue'
import { CommonMudule } from '@/store/modules/common'
import { UserModule } from '@/store/modules/user'

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'SidebarItem',
  components: {
    SidebarItemLink
  }
})
export default class extends Vue {
  @Prop({ required: true }) private item!: RouteConfig
  @Prop({ default: false }) private isCollapse!: boolean
  @Prop({ default: true }) private isFirstLevel!: boolean
  @Prop({ default: '' }) private basePath!: string
  @Prop({ default: '' }) private name!: string

  get showingChildNumber() {
    if (this.item.children) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const showingChildren = this.item.children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false
        } else {
          return true
        }
      })
      // 配置易管理：二次菜单只有一个，但是得显示一级菜单，让showingChildNumber长度大于1
      if (this.item.isAlwaysShow) {
        return showingChildren.length + 1
      } else {
        return showingChildren.length
      }
    }
    return 0
  }

  get getUserInfo() {
    return UserModule.userInfo
  }

  getItemAuth(itemData:any) {
    if (itemData.path === '/manage') {
      if (this.getUserInfo.roleManagerType && String(this.getUserInfo.roleManagerType) !== '4') {
        return true
      }
      return false
    }
    if (this.getUserInfo.roleManagerType && String(this.getUserInfo.roleManagerType) === '1') {
      return true
    }

    if (this.getUserInfo.roleManagerType && String(this.getUserInfo.roleManagerType) === '2') {
      const { path } = itemData
      if (path === '/manage/portfolioDataManage' || path === '/manage/investProductManage') {
        return true
      }
    }

    if (this.getUserInfo.roleManagerType && String(this.getUserInfo.roleManagerType) === '3') {
      const { path } = itemData
      if (path === '/manage/insuranceProductManage') {
        return true
      }
    }
  }

  showItem(itemData: any) {
    if (CommonMudule.authArr.includes(itemData.meta.offType)) {
      return false
    }
    return true
  }

  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item, path: '' }
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath
    }
    if (isExternal(this.basePath)) {
      return this.basePath
    }
    return path.resolve(this.basePath, routePath)
  }
}
