import { LoginParams, LoginRes } from '@/types/user'
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { logout, getUserInfo, fetchUserDetail } from '@/api/users'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import { Message } from 'element-ui'
import { CommonMudule } from './common'
import store from '@/store'

const initUser: LoginRes = {
  id: '',
  tenantId: null,
  createTime: '',
  createBy: null,
  createByName: null,
  deleted: false,
  username: '',
  name: '',
  flag: false,
  mobile: '',
  email: '',
  deptId: null,
  deptCode: null,
  token: '',
  lastLoginTime: '',
  userRoles: null,
  deptName: null,
  avatar: null,
  positionName: null,
  admin: true,
  roleManagerType: null
}

let userInfo: LoginRes
const localInfo = window.sessionStorage.getItem('userData')
if (localInfo) {
  userInfo = JSON.parse(localInfo)
} else {
  userInfo = initUser
}

export interface IUserState {
  token: string | null
  name: string
  introduction: string
  roles: string[],
  userInfo: LoginRes

}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || sessionStorage.getItem('token')
  public name = ''
  public introduction = ''
  public roles: string[] = []
  public userInfo: LoginRes = userInfo
  public userAuthObj = {
    '1': '全局管理员',
    '2': '管理员-投资业务',
    '3': '管理员-保险业务',
    '4': '用户',
  }

  @Mutation
  public SET_TOKEN(token: string) {
    this.token = token
    window.sessionStorage.setItem('token', token)
  }

  @Mutation
  public SET_USERDATA(userData: LoginRes) {
    this.userInfo = userData
    window.sessionStorage.setItem('userData', JSON.stringify(userData))
  }

  @Mutation
  public SET_USERDATA_ITEM<T, K extends keyof LoginRes>(keyVal: {field: K, value: any}) {
    const { field, value } = keyVal
    this.userInfo[field] = value
    console.log('userInfo', this.userInfo)
    window.sessionStorage.setItem('userData', JSON.stringify(this.userInfo))
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
  }

  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }
    const { data } = await getUserInfo({ /* Your params here */ })
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }
    const { roles, name, avatar, introduction } = data.user
    // roles must be a non-empty array
    if (!roles || roles.length <= 0) {
      throw Error('GetUserInfo: roles must be a non-null array!')
    }
    this.SET_ROLES(roles)
    this.SET_NAME(name)
    this.SET_INTRODUCTION(introduction)
  }

  @Action
  public UserDetailAction() {
    if (Reflect.has(this.userInfo, 'id')) {
      fetchUserDetail({
        userId: this.userInfo.id
      }).then((res) => {
        if (res.success && res.statusCode === 0) {
          this.SET_USERDATA(res.data)
        } else {
          Message({
            message: res.errorMsg,
            type: 'error',
            duration: 5 * 1000
          })
        }
      })
    }
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    await logout()
    removeToken()
    this.SET_TOKEN('')
    this.SET_USERDATA(initUser)
    this.SET_ROLES([])
    CommonMudule.SET_CUSTOMERID({
      id: '',
      customerName: ' '
    })
    CommonMudule.SET_ALLOW_DATA([])
    Message({
      message: '退出登录',
      type: 'success',
      duration: 5 * 1000
    })
  }
}

export const UserModule = getModule(User)
