/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'asset_plan': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M12.8 0A1.2 1.2 0 0114 1.2v13.6a1.2 1.2 0 01-1.2 1.2H1.2A1.2 1.2 0 010 14.8V1.2A1.2 1.2 0 011.2 0h11.6zm-.418 8.56a.5.5 0 00-.707 0l-3.72 3.719-2.427-2.601a.51.51 0 00-.007-.007l-.025-.03a.5.5 0 00-.707 0l-3.002 3.002a.5.5 0 00.707.707l2.634-2.635 2.45 2.626a.5.5 0 00.721.009l4.083-4.084a.5.5 0 000-.707zM7.02 6.5H2.1a.5.5 0 00-.09.992l.09.008h4.92a.5.5 0 100-1zm3.08-3h-8a.5.5 0 00-.09.992l.09.008h8a.5.5 0 100-1z"/>'
  }
})
