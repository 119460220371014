/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'invest_product_admin': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 0c2.015 0 3.682 1.304 3.96 3H14a1 1 0 011 1v11a1 1 0 01-1 1H2a1 1 0 01-1-1V4a1 1 0 011-1h2.04C4.318 1.304 5.985 0 8 0zm1.5 13h-3a.5.5 0 100 1h3a.5.5 0 100-1zm1-3h-5a.5.5 0 00-.09.992L5.5 11h5a.5.5 0 100-1zM8 1c-1.451 0-2.662.859-2.94 2h5.88C10.662 1.86 9.451 1 8 1z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
