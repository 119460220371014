/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'childs_protect': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M15 4.098h0v8.625a.4.4 0 01-.4.4H2.4a.4.4 0 01-.4-.4V4.116h0" _stroke="#FFF" stroke-linecap="round"/><path pid="1" d="M3.344 2.066a6.439 6.439 0 012.177.057c.755.151 1.514.445 2.278.882A.4.4 0 018 3.352v8.771c-.813-.498-1.64-.832-2.48-1a6.357 6.357 0 00-2.288-.042.2.2 0 01-.232-.197V2.462a.4.4 0 01.344-.396zM13.656 2.066a6.439 6.439 0 00-2.177.057c-.755.151-1.514.445-2.278.882A.4.4 0 009 3.352v8.771c.813-.498 1.64-.832 2.48-1a6.357 6.357 0 012.288-.042.2.2 0 00.232-.197V2.462a.4.4 0 00-.344-.396z" _fill="#FFF"/></g>'
  }
})
