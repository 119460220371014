/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sys_intro': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M5.419 2.356a2.743 2.743 0 110 5.486 2.743 2.743 0 010-5.486zm0 1.829a.914.914 0 100 1.828.914.914 0 000-1.828zM8.95 4.218h5.292a.8.8 0 110 1.6H8.95a.2.2 0 01-.188-.267 1.57 1.57 0 00.096-.533 1.57 1.57 0 00-.096-.533.2.2 0 01.188-.267zM1.934 4.218h-.406a.714.714 0 00-.715.715v.17c0 .395.32.715.715.715h.406a.2.2 0 00.189-.267 1.57 1.57 0 01-.096-.533c0-.178.032-.355.096-.533a.2.2 0 00-.189-.267z"/><g><path pid="1" d="M10.333 8.756a2.743 2.743 0 100 5.486 2.743 2.743 0 000-5.486zm0 1.829a.914.914 0 110 1.828.914.914 0 010-1.828zM6.8 10.618H1.51a.8.8 0 100 1.6h5.292a.2.2 0 00.188-.267 1.57 1.57 0 01-.095-.533c0-.178.032-.355.095-.533a.2.2 0 00-.188-.267zM13.817 10.618h.407c.394 0 .714.32.714.715v.17c0 .395-.32.715-.714.715h-.407a.2.2 0 01-.188-.267 1.57 1.57 0 00.095-.533 1.57 1.57 0 00-.095-.533.2.2 0 01.188-.267z"/></g></g>'
  }
})
