import Vue from 'vue'
import Vuex from 'vuex'
import { IAppState } from './modules/app'
import { IUserState } from './modules/user'
import { CommonState } from './modules/common'
import { PortState } from './modules/portfolio'
import { AnalyState } from './modules/analy'

Vue.use(Vuex)

export interface IRootState {
  app: IAppState
  user: IUserState
  common: CommonState
  port: PortState
  analy: AnalyState
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({})
