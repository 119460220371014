import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Router, { RawLocation } from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the activeMenu showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-activeMenu'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

const originalPush = Router.prototype.push

Router.prototype.push = function push(location: RawLocation) {
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => err)
}

export const constRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
    meta: { hidden: true, title: '登录智能资产配置系统', activeMenu: '/login' },
    sort: 1
  },
  {
    path: '/userSelect',

    component: () =>
      import(
        /* webpackChunkName: "userSelect" */ '../views/login/user/index.vue'
      ),
    meta: {
      hidden: true,
      title: '智能资产配置系统',
      activeMenu: '/userSelect'
    },
    sort: 2
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      hidden: true,
      title: '404',
      activeMenu: '/404'
    },
    sort: 3
  },
  {
    path: '/',
    component: Layout,
    redirect: '/system',
    sort: 4,
    meta: {
      title: '首页',
      icon: 'home',
      activeMenu: '/system'
    },
    children: [
      {
        path: 'system',
        component: () =>
          import(/* webpackChunkName: "system" */ '@/views/system/index.vue'),
        meta: {
          title: '首页',
          icon: 'home',
          activeMenu: '/system'
        }
      }
    ]
  },
  {
    path: '/analys',
    component: Layout,
    redirect: '/analys/index',
    sort: 5,
    meta: {
      title: '财富体检',
      activeMenu: '/analys',
      offType: 'assetAnalysis'
    },
    children: [
      {
        path: 'index',
        component: () =>
          import(
            /* webpackChunkName: "financialAnalysis" */ '@/views/analys/financialAnalysis/index.vue'
          ),
        meta: {
          title: '财富体检',
          icon: 'finnace_suggest',
          activeMenu: '/analys',
          offType: 'assetAnalysis'
        }
      },
      {
        path: 'analyChart',
        component: () =>
          import(
            /* webpackChunkName: "asKYC" */ '@/views/analys/analyChart/index.vue'
          ),
        meta: {
          title: '家庭资产总览',
          hidden: true,
          activeMenu: '/analys',
          offType: 'assetAnalysis'
        }
      }
    ]
  },
  {
    path: '/portfolio',
    component: Layout,
    sort: 6,
    redirect: '/portfolio/index',
    meta: {
      title: '投资组合规划',
      hidden: false,
      activeMenu: '/portfolio',
      offType: 'invest'
    },
    children: [
      {
        sort: '6-1',
        path: 'index',
        component: () =>
          import(
            /* webpackChunkName: "portfolio_groupOne" */ '@/views/portfolio/index.vue'
          ),
        meta: {
          title: '投资组合规划',
          icon: 'invest_plan',
          activeMenu: '/portfolio',
          offType: 'invest'
        }
      },
      {
        path: 'portkyc/:id',
        component: () =>
          import(
            /* webpackChunkName: "portKYC" */ '@/views/portfolio/portKYC/index.vue'
          ),
        meta: {
          title: '投资组合规划',
          icon: 'form',
          hidden: true,
          activeMenu: '/portfolio',
          offType: 'invest'
        }
      },
      {
        path: 'group1',
        component: () =>
          import(
            /* webpackChunkName: "portfolio_groupOne" */ '@/views/portfolio/index.vue'
          ),
        meta: {
          title: '投资组合规划',
          icon: 'form',
          hidden: true,
          activeMenu: '/portfolio',
          offType: 'invest'
        }
      },
      {
        path: 'group2',
        component: () =>
          import(
            /* webpackChunkName: "portfolio_aloneIndex" */ '@/views/portfolio/groupTwo/index.vue'
          ),
        meta: {
          title: '资产配置组合',
          icon: 'form',
          hidden: true,
          activeMenu: '/portfolio',
          offType: 'invest'
        }
      },
      {
        activeMenu: '/portfolio',
        path: 'group3',
        component: () =>
          import(
            /* webpackChunkName: "portfolio_groupThree" */ '@/views/portfolio/groupThree/index.vue'
          ),
        meta: {
          title: '资产配置总览',
          icon: 'form',
          hidden: true,
          activeMenu: '/portfolio',
          offType: 'invest'
        }
      }
    ]
  },
  {
    path: '/basicSecurity',

    component: Layout,
    meta: {
      title: '家庭基础保障规划',
      activeMenu: '/basicSecurity'
    },
    sort: 7,
    children: [
      {
        path: 'index',

        component: () =>
          import(
            /* webpackChunkName: "basicSecurity" */ '@/views/basicSecurity/financialAnalysis/index.vue'
          ),
        meta: {
          title: '家庭基础保障规划',
          icon: 'family_plan',
          activeMenu: '/basicSecurity'
        }
      },
      {
        path: 'basicKyc',

        component: () =>
          import(
            /* webpackChunkName: "basicSecurity" */ '@/views/basicSecurity/index.vue'
          ),
        meta: {
          title: '资产相关',
          icon: 'form',
          hidden: true,
          activeMenu: '/basicSecurity'
        }
      },
      {
        path: 'base',
        component: () =>
          import(
            /* webpackChunkName: "basicSecurity" */ '@/views/basicSecurity/base.vue'
          ),
        meta: {
          title: '家庭基础保障规划',
          icon: 'form',
          hidden: true,
          activeMenu: '/basicSecurity'
        }
      },
      {
        path: 'basePlanDetail',
        component: () =>
          import(
            /* webpackChunkName: "basePlanDetail" */ '@/views/basicSecurity/basePlanDetail.vue'
          ),
        meta: {
          title: '家庭基础保障规划',
          icon: 'form',
          hidden: true,
          activeMenu: '/basicSecurity'
        }
      }
    ]
  },
  {
    path: '/childTeach',
    component: Layout,
    meta: {
      title: '子女教育规划',
      activeMenu: '/childTeach',
      offType: 'childEdu'
    },
    sort: 8,
    children: [
      {
        path: 'index',
        component: () =>
          import(
            /* webpackChunkName: "childTeach" */ '@/views/childTeach/index.vue'
          ),
        meta: {
          title: '子女教育规划',
          activeMenu: '/childTeach',
          icon: 'child_plan',
          offType: 'childEdu'
        }
      },
      {
        path: 'child2',
        component: () =>
          import(
            /* webpackChunkName: "asKYC" */ '@/views/childTeach/template/twoIndex.vue'
          ),
        meta: {
          title: '资产相关',
          icon: 'form',
          activeMenu: '/childTeach',
          hidden: true,
          offType: 'childEdu'
        }
      },
      {
        path: 'child3',
        component: () =>
          import(
            /* webpackChunkName: "childTeach" */ '@/views/childTeach/template/three/index.vue'
          ),
        meta: {
          title: '子女教育保障规划',
          icon: 'form',
          activeMenu: '/childTeach',
          hidden: true,
          offType: 'childEdu'
        }
      },
      {
        path: 'child3_others',
        component: () =>
          import(
            /* webpackChunkName: "childTeach" */ '@/views/childTeach/template/three/others.vue'
          ),
        meta: {
          title: '子女教育保障规划',
          icon: 'form',
          activeMenu: '/childTeach',
          hidden: true,
          offType: 'childEdu'
        }
      }
    ]
  },
  {
    path: '/pension',

    component: Layout,
    meta: {
      title: '退休养老规划',
      activeMenu: '/pension',
      offType: 'pension'
    },
    sort: 9,
    children: [
      {
        path: 'index',
        component: () =>
          import(
            /* webpackChunkName: "pension" */ '@/views/pension/financialAnalysis/index.vue'
          ),
        meta: {
          title: '退休养老规划',
          icon: 'pession_plan',
          activeMenu: '/pension',
          offType: 'pension'
        }
      },
      {
        path: 'typein',

        component: () =>
          import(
            /* webpackChunkName: "pensionType" */ '@/views/pension/index.vue'
          ),
        meta: {
          title: '退休养老规划',
          icon: 'form',
          activeMenu: '/pension',
          hidden: true,
          offType: 'pension'
        }
      },
      {
        path: 'plan',

        component: () =>
          import(
            /* webpackChunkName: "pensionPlan" */ '@/views/pension/plan/index.vue'
          ),
        meta: {
          title: '退休养老规划',
          icon: 'form',
          activeMenu: '/pension',
          hidden: true,
          offType: 'pension'
        }
      },
      {
        path: 'planDetail',

        component: () =>
          import(
            /* webpackChunkName: "pensionPlanDetail" */ '@/views/pension/planDetail/index.vue'
          ),
        meta: {
          title: '退休养老规划',
          icon: 'form',
          activeMenu: '/pension',
          hidden: true,
          offType: 'pension'
        }
      }
    ]
  },
  // {
  //   path: '/story',
  //   sort: 12,
  //   component: Layout,
  //   meta: {
  //     title: '场景化故事线',
  //     activeMenu: '/story'
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () =>
  //         import(/* webpackChunkName: "story" */ '@/views/story/index.vue'),
  //       meta: {
  //         title: '场景化故事线',
  //         activeMenu: '/story',
  //         icon: 'el-icon-video-camera-solid'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/assetsConfig',
  //   meta: {
  //     title: '资产配置策略指引',
  //     activeMenu: '/assetsConfig'
  //   },
  //   component: Layout,
  //   sort: 10,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "assetsConfig" */ '../views/assetsConfig/index.vue'
  //         ),
  //       meta: {
  //         title: '资产配置策略指引',
  //         activeMenu: '/assetsConfig',
  //         icon: 'asset_plan'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/instituteConfig',
    resourceId: '20',
    parentId: null,
    meta: {
      title: '资产配置策略指引',
      activeMenu: '/instituteConfig',
      sort: 15,
      offType: 'assets'
    },
    component: Layout,
    children: [
      {
        path: 'index',
        component: () =>
          import(
            /* webpackChunkName: "assetsConfig" */ '@/views/instituteConfig/index.vue'
          ),
        meta: {
          title: '资产配置策略指引',
          activeMenu: '/instituteConfig',
          icon: 'asset_plan',
          code: 20,
          offType: 'assets'
        }
      }
    ]
  },
  {
    path: '/otherOpt',
    resourceId: '23',
    parentId: null,
    component: Layout,
    meta: {
      title: '财富聚焦',
      activeMenu: '/otherOpt',
      sort: 17,
      offType: 'wealth'
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: () =>
          import(
            /* webpackChunkName: "otherOpt" */ '@/views/otherOption/index.vue'
          ),
        meta: {
          title: '财富聚焦',
          activeMenu: '/otherOpt',
          sort: 9,
          icon: 'el-icon-s-shop',
          code: 23,
          offType: 'wealth'
        }
      },
      {
        path: 'otherOptDetail',
        name: 'otherOptDetail',
        component: () =>
          import(
            /* webpackChunkName: "otherOpt" */ '@/views/otherOption/otherOptionDetail.vue'
          ),
        meta: {
          title: '财富聚焦详情',
          activeMenu: '/otherOpt',
          hidden: true,
          offType: 'wealth'
        }
      }
    ]
  },
  {
    path: '/download',
    component: Layout,
    meta: {
      title: '下载建议书',
      activeMenu: '/download'
    },
    sort: 11,
    children: [
      {
        path: 'index',
        component: () =>
          import(
            /* webpackChunkName: "download" */ '../views/download/index.vue'
          ),
        meta: {
          title: '下载建议书',
          icon: 'down',
          activeMenu: '/download'
        }
      }
    ]
  },
  {
    path: '/print',
    sort: 12,
    component: () =>
      import(/* webpackChunkName: "print" */ '../views/print/index.vue'),
    meta: {
      hidden: true,
      title: '下载建议书',
      activeMenu: '/print'
    }
  },
  {
    path: '/sub-radar-board',
    component: Layout,
    resourceId: '30',
    meta: {
      title: '市场雷达',
      activeMenu: '/sub-radar-board',
      sort: 18,
      offType: 'marketRadar'
    },

    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "sub-radar-board" */ '../views/manage/dashBi/radarBoard.vue'
          ),
        name: '_radarBoard',
        meta: {
          title: '市场雷达',
          icon: 'el-icon-s-promotion',
          activeMenu: '/sub-radar-board',
          code: 30,
          routerBase: '/sub-radar-board',
          offType: 'marketRadar',
          noCache: true
        }
      },
      {
        path: '*',
        name: '_radarBoard',
        component: () =>
          import(
            /* webpackChunkName: "sub-radar-board" */ '../views/manage/dashBi/radarBoard.vue'
          ),
        meta: {
          title: '市场雷达',
          icon: 'el-icon-s-promotion',
          activeMenu: '/sub-radar-board',
          hidden: true,
          routerBase: '/sub-radar-board',
          offType: 'marketRadar',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/sub-house-board',
    component: Layout,
    resourceId: '30',
    meta: {
      title: '房产看板',
      activeMenu: '/sub-house-board',
      sort: 18,
      offType: 'marketRadar'
    },

    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "sub-house-board" */ '../views/manage/dashBi/houseBoard.vue'
          ),
        name: '_houseBoard',
        meta: {
          title: '房产看板',
          icon: 'el-icon-office-building',
          activeMenu: '/sub-house-board',
          code: 30,
          routerBase: '/sub-house-board',
          offType: 'marketRadar',
          noCache: true
        }
      },
      {
        path: '*',
        name: '_houseBoard',
        component: () =>
          import(
            /* webpackChunkName: "sub-house-board" */ '../views/manage/dashBi/houseBoard.vue'
          ),
        meta: {
          title: '房产看板',
          icon: 'el-icon-s-promotion',
          activeMenu: '/sub-house-board',
          hidden: true,
          routerBase: '/sub-house-board',
          offType: 'marketRadar',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/userManage',
    activeMenu: '/userManage',
    meta: {
      title: '客户管理',
      icon: 'customer',
      activeMenu: '/userManage'
    },
    component: Layout,
    sort: 13,
    children: [
      {
        path: '/select',

        component: () =>
          import(
            /* webpackChunkName: "userManage" */ '../views/login/user/index.vue'
          ),
        meta: {
          title: '客户管理',
          icon: 'customer',
          activeMenu: '/userManage'
        }
      },
      {
        path: '/changePwd',
        component: () =>
          import(
            /* webpackChunkName: "userManage" */ '../views/login/user/changePwd.vue'
          ),
        meta: {
          title: '修改密码',
          hidden: true
        }
      },
      {
        path: '/changeAvatar',
        component: () =>
          import(
            /* webpackChunkName: "userManage" */ '../views/login/user/changeAvatar.vue'
          ),
        meta: {
          title: '修改头像',
          hidden: true
        }
      }
    ]
  },
  {
    path: '/manage',
    activeMenu: '/manage',
    meta: {
      title: '管理后台',
      icon: 'admin_back',
      activeMenu: '/manage'
    },
    component: Layout,
    sort: 15,
    children: [
      {
        path: '/manage/portfolioDataManage',

        component: () =>
          import(
            /* webpackChunkName: "portfolioDataManage" */ '../views/manage/portfolioDataManage/index.vue'
          ),
        meta: {
          title: '投资相关参数',
          icon: 'invest_planning_params',
          activeMenu: '/manage/portfolioDataManage'
        }
      },
      {
        path: '/manage/investProductManage',

        component: () =>
          import(
            /* webpackChunkName: "investProductManage" */ '../views/manage/investProductManage/index.vue'
          ),
        meta: {
          title: '投资产品管理',
          icon: 'invest_product_admin',
          activeMenu: '/manage/investProductManage'
        }
      },
      {
        path: '/manage/insuranceProductManage',

        component: () =>
          import(
            /* webpackChunkName: "insuranceProductManage" */ '../views/manage/insuranceProductManage/index.vue'
          ),
        meta: {
          title: '保险产品管理',
          icon: 'Insurance_product_admin',
          activeMenu: '/manage/insuranceProductManage'
        }
      },
      {
        path: '/manage/loginUserManage',

        component: () =>
          import(
            /* webpackChunkName: "loginUserManage" */ '../views/manage/loginUserManage/index.vue'
          ),
        meta: {
          title: '登录人员管理',
          icon: 'login_personnel_admin',
          activeMenu: '/manage/loginUserManage'
        }
      }
    ]
  },
  {
    path: '/tenant',
    activeMenu: '/tenant',
    isAlwaysShow: true,
    meta: {
      title: '配置易管理',
      icon: 'el-icon-s-tools',
      activeMenu: '/tenant',
      menu: true,
    },
    component: Layout,
    sort: 19,
    children: [
      {
        path: '/tenant/storyline',
        resourceId: '1909',
        parentId: '19',
        component: () =>
          import(
            /* webpackChunkName: "storyline" */ '../views/tenant/uploadStory/index.vue'
          ),
        meta: {
          title: '故事线上传',
          icon: 'el-icon-upload',
          activeMenu: '/tenant/storyline'
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true, title: '404' },
    sort: 14
  }
]

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    base: process.env.BASE_URL,
    routes: constRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  ;(router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
