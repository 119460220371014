
export const rmbFilter = (value: number) => { // 展示万元
  if (!value) return '0.00'
  let fuNum = false
  if (value < 0) {
    fuNum = true
    value = Math.abs(value)
  }
  const nValue = Number(value)

  if (nValue < 10000) {
    if (fuNum) {
      return '-' + Number(nValue.toFixed(0)) / 10000 + '万'
    } else {
      return Number(nValue.toFixed(0)) / 10000 + '万'
    }
  }

  value = nValue / 10000
  const intPart = value.toString().split('.')[0]
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  let floatPart = '.00'
  const v2 = value.toString().split('.')
  if (v2.length === 2) {
    floatPart = v2[1].toString()
    if (floatPart.length === 1) {
      if (fuNum) {
        return '-' + intPartFormat + '.' + floatPart + '0' + '万'
      } else {
        return intPartFormat + '.' + floatPart + '0' + '万'
      }
    } else if (floatPart.length > 2) { // 11,420,601.35万元
      if (fuNum) {
        return '-' + intPartFormat + '.' + floatPart.slice(0, 2) + '万'
      } else {
        return intPartFormat + '.' + floatPart.slice(0, 2) + '万'
      }
    } else { // 整万数  =0 = 1 >2
      if (fuNum) {
        return '-' + intPartFormat + '.' + floatPart + '万'
      } else {
        return intPartFormat + '.' + floatPart + '万'
      }
    }
  } else {
    if (fuNum) {
      return '-' + intPartFormat + floatPart + '万'
    } else {
      return intPartFormat + floatPart + '万'
    }
  }
}

export const rmbFilterNum = (value: number) => { // 不展示万元
  if (!value) return '0.00'
  let fuNum = false
  if (value < 0) {
    fuNum = true
    value = Math.abs(value)
  }
  const nValue = Number(value)

  if (nValue < 10000) {
    if (fuNum) {
      return '-' + Number(nValue.toFixed(0)) / 10000
    } else {
      return Number(nValue.toFixed(0)) / 10000
    }
  }

  value = nValue / 10000
  const intPart = value.toString().split('.')[0]
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  let floatPart = '.00'
  const v2 = value.toString().split('.')

  if (v2.length === 2) {
    floatPart = v2[1].toString()
    if (floatPart.length === 1) {
      if (fuNum) {
        return '-' + intPartFormat + '.' + floatPart + '0'
      } else {
        return intPartFormat + '.' + floatPart + '0'
      }
    } else if (floatPart.length > 2) {
      if (fuNum) {
        return '-' + intPartFormat + '.' + floatPart.slice(0, 2)
      } else {
        return intPartFormat + '.' + floatPart.slice(0, 2)
      }
    } else {
      if (fuNum) {
        return '-' + intPartFormat + '.' + floatPart
      } else {
        return intPartFormat + '.' + floatPart
      }
    }
  } else {
    if (fuNum) {
      return '-' + intPartFormat + floatPart
    } else {
      return intPartFormat + floatPart
    }
  }
}
// 四舍五入保留2位小数（若第二位小数为0，则保留一位小数）
export const keepTwoDecimal = (num: any) => {
  let result = parseFloat(num)
  if (isNaN(result)) {
    alert('传递参数错误，请检查！')
    return false
  }
  result = Math.round(num * 100) / 100
  return result
}
// 四舍五入保留2位小数（不够位数，则用0替补）
export const keepTwoDecimalFull = (num:any) => {
  let result = parseFloat(num)
  if (isNaN(result)) {
    // alert('传递参数错误，请检查！')
    return 0
  }
  result = Math.round(num * 100) / 100
  var s_x = result.toString()
  var pos_decimal = s_x.indexOf('.')
  if (pos_decimal < 0) {
    pos_decimal = s_x.length
    s_x += '.'
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0'
  }
  return s_x
}

// 金额添加千分位
export const comdifyKill = (n:string) => {
  if (!n) return n
  n = n.toString()
  const str = n.split('.')
  const re = /\d{1,3}(?=(\d{3})+$)/g
  const n1 = str[0].replace(re, '$&,')
  return str.length > 1 && str[1] ? `${n1}.${str[1].slice(0, 2)}` : `${n1}.00`
}

// 金额添加千分位
export const comdifyKillNoZero = (n:any) => {
  if (!n) return n
  n = n.toString()
  const str = n.split('.')
  const re = /\d{1,3}(?=(\d{3})+$)/g
  const n1 = str[0].replace(re, '$&,')
  return str.length > 1 && str[1] ? `${n1}.${str[1].slice(0, 2)}` : `${n1}`
}
// 金额移除千分位
export const removeComdifyKill = (n:string) => {
  if (!n) {
    return ''
  }
  const _numStr = n.split(',')
  const arr = []
  for (let i = 0; i < _numStr.length; i++) {
    const _w = _numStr[i]
    arr.push(_w)
  }
  return +arr.join('')
}
