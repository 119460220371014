/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'family_plan': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M8 1.333a8 8 0 00-8 8h7.493v4.51c-.245.561-.541.605-.78.608h-.058c-.452 0-.717-.211-.826-.678-.04-.167-.04-.419 0-.754.027-.415-.132-.622-.475-.622-.343 0-.52.207-.53.622v.268c0 .989.187 2.163 1.83 2.163 1.645 0 1.858-1.477 1.858-1.477l.001-4.64H16a8 8 0 00-7.75-7.996zM8 .708c-.376 0-.782.108-1.058.246C7 .29 7.562 0 8 0c.439 0 1.029.344 1.029.954C8.68.816 8.376.708 8 .708z"/></g>'
  }
})
