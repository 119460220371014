/* eslint-disable standard/no-callback-literal */
import moment from 'moment'
import { isNull } from 'lodash'
export const isValidUsername = (str: any) => ['admin', 'editor'].indexOf(str.trim()) >= 0

export const isExternal = (path: any) => /^(https?:|mailto:|tel:)/.test(path)
/**
 * 数据返回null的处理
 */
export const parsePrice = (val: any) => {
  if (isNull(val)) {
    return 0
  }
  if (isNaN(parseFloat(val))) {
    return 0
  }
  return Number(val)
}
/**
 * form 数据过滤
 */
export const formatInput = {
  date: (value:any, pattern:string) => {
    pattern = pattern || 'YYYY-MM-DD HH:mm'
    if (!value || value === '') return ''
    return moment(value).format(pattern)
  },
  scope: (value: { length: any; }) => {
    if (!value.length) return ''
    return value
  }
}
/**
 * 格式化时间
 */
export const formatDate = (value:any, pattern:string) => {
  pattern = pattern || 'YYYY-MM-DD HH:mm'
  if (!value || value === '') return ''
  return moment(value).format(pattern)
}
/**
 * 格式化范围
 */
export const formatScope = (value: { length: any; }) => {
  if (!value.length) return ''
  return value
}
/**
 * 校验数据类型
 */
export const checkType = (value:any) => {
  return Object.prototype.toString.call(value)
}
/**
 * 验证小数2位或4位
 */
export const isDecimal = (value: string) => {
  return /^\d{0,8}\.{0,1}((\d{2})|(\d{4}))?$/.test(value)
}
/**
 * 只能输入0 或非零开头的数字
 */
export const isInteger = (value: string) => {
  return /^(0|[1-9][0-9]*)$/.test(value)
}

/**
 * 验证小数4位
 */
export const isDecimalFour = (value: string) => {
  return /^\d+(\.\d{1,4})?$/.test(value)
}
/**
 * 校验金额
 */
export const isMoney = (value: any) => {
  return /(^[1-9]([0-9]+)?(\.[0-9]{1,4})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(
    value
  )
}
/**
 * 证件号 只能输入数字或字母
 */
export const checkCard = (value: string) => {
  return /^[a-zA-Z\d]+$/.test(value)
}
/**
 * 证件号 只能输入数字或字母或括号 香港身份证校验
 */
export const checkCard3 = (value: string) => {
  return /[a-zA-Z]{1,2}[0-9]{5}([0-9A])/.test(value)
}
/**
 * 证件号 只能输入数字或字母或括号 台湾身份证校验
 */
export const checkCardTW = (value: string) => {
  return /[a-zA-Z][0-9]{9}/.test(value)
}
/**
 * 证件号 只能输入数字或字母或汉字
 */
export const checkCard2 = (value: string) => {
  return /^[a-zA-Z\d\u4e00-\u9fa5]+$/.test(value)
}
/**
 * 校验手机号
 */
export const isPhone = (phone: string) => {
  return /^1[0-9]{10}$/.test(phone)
}
/**
 * 校验密码
 */
export const isPassword = (value: string) => {
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/.test(value)
}
/**
 * 台胞证
 */
export const checkCardTB = (value: string) => {
  return /^(\d{8}|\d{10}\([a-zA-Z]\))$/.test(value)
}
/**
 * 澳门身份证
 */
export const checkCardAM = (value: string) => {
  return /^(\d{7}\([0-9]\))$/.test(value)
}
/**
 * 港澳居民来往内地通行证
 */
export const checkCardGAJ = (value: string) => {
  return /^(H|M)\d{10}$/.test(value)
}
/**
 *验证 0-99 之间的整数
 */
export const isNine = (value: string) => {
  return /^([1-9]\d|\d)$/.test(value)
}
/**
 * 验证小数2位
 */
export const isDouDecimal = (value: string) => {
  return /^\d+(\.\d{1,2})?$/.test(value)
}
/**
 *验证 1-99 之间的整数
 */
export const isFtoN = (value: string) => {
  return /^([1-9]\d?|99)$/.test(value)
}
/**
 * 最多四位小数
 */
export const isFourDecimal = (value: string) => {
  return /^([1-9]\d{0,200}|0)(\.\d{1,4})?$/.test(value)
}
/**
 *验证 1-999之间的整数
 */
export const isFtoHn = (value: string) => {
  return /^[1-9][0-9]{0,2}$/.test(value)
}
/**
 * 1-9999
 */
export const isToFn = (value: string) => {
  return /^[1-9][0-9]{0,3}$/.test(value)
}
/**
 * 1-30正整数
 */
export const isToTh = (value: string) => {
  return /^([12][0-9]|30|[1-9])$/.test(value)
}
/**
 *验证 https://或者https://开头
 */
export const urlHeader = (value: string) => {
  return /^http(s)?:\/\//.test(value)
}
/**
 *验证 https://或者https://或者/开头
 */
export const urlHeader2 = (value: string) => {
  return /^http(s)?:\/\/|\//.test(value)
}
/**
 *验证 https://或者https://开头
 */
export const urlWatch = (value: string) => {
  return /^http(s)?:\/\//.test(value)
}
/**
 * 邮箱
 */
export const email = (value: string) => {
  return /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
    value
  )
}
/**
 * 正整数(含0 最多两位小数)
 */
export const interTwo = (value: string) => {
  return /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)
}
/**
 * 非0正整数
 */
export const isEcpInteger = (value: string) => {
  return /^\+?[1-9][0-9]*$/.test(value)
}
/**
 *  正数，且最多保留两位小数
 */
export const isTwDec = (value: string) => {
  return /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)
}
/**
 * 0-100(不包含0)，且最多保留两位小数
 */
export const isTwHun = (value: string) => {
  return /^\d\.([1-9]{1,2}|[0-9][1-9])$|^[1-9]\d{0,1}(\.\d{1,2}){0,1}$|^100(\.0{1,2}){0,1}$/.test(
    value
  )
}
/**
 * 正整数
 */
export const isPositiveInteger = (value: string) => {
  return /^(0|[1-9][0-9]*)$/.test(value)
}
/**
 *  最多保留两位小数
 */
export const isTwMoney = (value: string) => {
  return /^[+-]?\d*\.?\d{1,2}$/.test(value)
}
/**
 *  最多保留四位小数
 */
export const isFourMoney = (value: string) => {
  return /^[+-]?\d*\.?\d{1,4}$/.test(value)
}
/**
 *  手机号座机号
 */
export const isLandline = (value: string) => {
  return /^((800[0-9]{7})|(400[0-9]{7})|(0\d{2,3}-\d{7,8})|(1[0-9]\d{9}))$/.test(
    value
  )
}
/**
 * 验证链接是https开头
 */
export const regUrl = (value: string) => {
  return /^(https):\/\//.test(value)
}
/**
 * 正整数 最多4位小数
 */
export const isFourInteger = (value: string) => {
  return /^([1-9][0-9]*(\.[0-9]{1,4})?|0\.(?!0+$)[0-9]{1,4})$/.test(value)
}
// 带有单位
export const formatRMB = (value:any) => {
  if (value === '' || value === undefined) return '-'
  if (String(value) === '0') return '0'
  const nValue = Number(value)

  if (nValue < 10000) {
    return nValue
  }

  value = nValue / 10000
  const intPart = parseInt(value)
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  let floatPart = '.00'
  const v2 = value.toString().split('.')

  if (v2.length === 2) {
    floatPart = v2[1].toString()
    if (floatPart.length === 1) {
      return intPartFormat + '.' + floatPart + '0' + '万'
    } else if (floatPart.length > 4) {
      const yu = value.toFixed(4) % 10
      if (yu === 0) {
        return parseInt(value.toFixed(4)) + '万'
      } else {
        return parseFloat(value.toFixed(4)) + '万'
      }
    } else {
      return intPartFormat + '.' + floatPart + '万'
    }
  } else {
    return intPartFormat + '万'
  }
}
export const formatRMB2 = (value:any) => {
  if (value === '' || value === undefined) return '-'
  if (String(value) === '0') return '0'
  const nValue = Number(value)

  if (nValue < 10000) {
    return nValue
  }

  value = nValue / 10000
  const intPart = parseInt(value)
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1')
  let floatPart = '.00'
  const v2 = value.toString().split('.')

  if (v2.length === 2) {
    floatPart = v2[1].toString()
    if (floatPart.length === 1) {
      return intPartFormat + '.' + floatPart + '0' + ''
    } else if (floatPart.length > 4) {
      const yu = value.toFixed(4) % 10
      if (yu === 0) {
        return parseInt(value.toFixed(4)) + ''
      } else {
        return parseFloat(value.toFixed(4)) + ''
      }
    } else {
      return intPartFormat + '.' + floatPart + ''
    }
  } else {
    return intPartFormat + ''
  }
}
// 四舍五入保留2位小数（不够位数，则用0替补）
export const keepTwoDecimalFullVal = (num:any) => {
  let result = parseFloat(num)
  if (isNaN(result)) {
    // alert('传递参数错误，请检查！')
    return 0
  }
  result = Math.round(num * 100) / 100
  var s_x = result.toString()
  var pos_decimal = s_x.indexOf('.')
  if (pos_decimal < 0) {
    pos_decimal = s_x.length
    s_x += '.'
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0'
  }
  return s_x
}

export const MoneyTest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/

// 金额添加千分位
export const comdify = (n:string) => {
  if (!n) return n
  const str = String(n).split('.')
  const re = /\d{1,3}(?=(\d{3})+$)/g
  const n1 = str[0].replace(re, '$&,')
  return str.length > 1 && str[1] ? `${n1}.${str[1].slice(0, 2)}` : `${n1}.00`
}
// 去除千分位中的‘，’
export const delcommafy = function(num:string) {
  if (!num) return num
  num = num.toString()
  num = num.replace(/,/gi, '')
  return num
}
export const valdateFn = function(rule:RegExp, val:string, cb:Function) {
  if (val) {
    const inputVal = delcommafy(val)
    if (rule.test(inputVal)) {
      cb()
    } else {
      cb(new Error('只能是数字金额'))
    }
  }
}
// 验证金额数字可以为负数
export const moneyValid = function(rule:RegExp, val:string, cb:Function) {
  valdateFn(/((^-?[1-9]\d*)|^-?0)(\.\d{0,2}){0,1}$/, val, cb)
}
// 验证金额数字不可以为负数
export const moneyNValid = function(rule:RegExp, val:string, cb:Function) {
  console.log('rule', rule)
  console.log('val', val)
  console.log('cb', cb)
  if (val) {
    const inputVal = delcommafy(val)
    if (rule.test(inputVal)) {
      cb()
    } else {
      cb(new Error('只能是数字金额'))
    }
  }
}

// 获取输入框的值
export const getInputValue = function(el: any) {
  const inputVal = el.target.value || ''
  return comdify(delcommafy(inputVal))
}

// 金额添加千分位2
export const comdify2 = (n: string) => {
  if (!n) return n
  const str = String(n).split('.')
  const re = /\d{1,3}(?=(\d{3})+$)/g
  const n1 = str[0].replace(re, '$&,')
  return str.length > 1 && str[1] ? `${n1}.${str[1].slice(0, 2)}` : `${n1}`
}

// 消息弹框
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messageBoxFn = (_that: any, fn: Function) => {
  _that
    .$confirm('是否执行该操作?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
    .then(() => {
      fn(true)
    })
    .catch(() => {
      _that.$message({
        type: 'info',
        message: '已取消该操作',
      })
      fn(false)
    })
}
