import Axios, { AxiosResponse } from 'axios'
import request from '@/utils/request'
import { CustomInfo } from '@/types/common'
/**
 *  公有字典数据
 *  职业
 *  国籍
 *  家庭状况
 *  币种 Currency
 */

export const OptionList = (params:any) =>
  request({
    url: '/blackcat/dic',
    method: 'get',
    params
  })
/**
 * 获取美元兑人民币汇率
 */
export const getRMBRate = () =>
  request({
    url: '/blackcat/currency/usd/cny',
    method: 'get',
  })
  /**
 * 子类客户资产列勾选对勾
 */
export const getcomplete = (params:CustomInfo) =>
  request({
    url: '/blackcat/asset/customer/sub',
    method: 'get',
    params
  })
/**
 * 获取图片信息
 */
export const getImgSource = () => {
  const url = process.env.NODE_ENV === 'development' ? '/aap/img/imgText' : '/imgText'
  const res = Axios.get(url)
  return res
}
/**
 * 获取权限
 */
export const getAllocation = () =>
  request({
    url: '/blackcat/allocation/off',
    method: 'get'
  })
