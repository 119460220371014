/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pession_plan': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<g _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M7.5 1.971c1.736-.952 2.86-1.02 4.403-.952.743.366 1.069.595 1.946 1.378.987.144 1.17.396 1.17.917 0 .148-.686.427-1.687.74a3 3 0 11-5.806 1.391c-.88.142-1.536.194-1.731.094-.528-.272-.287-.758.722-1.46.184-.713.512-1.416.983-2.108zM.5 15.82h8.689v-3.67c.442.21.662.372.662.487 0 .171.915 1.16 1.65 0 0-.487.218-.604.471-.604.36 0 .703-.125.518 3.787h1.318v-3.47c-.293-1.204-1.062-1.586-2.307-1.146-.34 0-3.655-3.18-4.001-3.358-.346-.179-1.734-1.848-3.326-.49C2.58 8.712.888 10.88.5 14.905v.914z"/></g>'
  }
})
