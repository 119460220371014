/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'home': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.5 12.428a1 1 0 00-1-1h-1a1 1 0 00-1 1v3.258H3.675a2 2 0 01-2-2l-.001-5.793-.328.329a.5.5 0 11-.707-.708L7.82.334a.5.5 0 01.707 0l.026.027a.506.506 0 01.04.036l7.117 7.117a.5.5 0 01-.707.708l-.33-.33.002 5.794a2 2 0 01-2 2H9.5z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
