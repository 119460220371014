
export const validator = (parent:any) => {
  const valid: any[] = []
  parent.$children.map((child:any) => {
    getClassName(child, valid)
  })
  const opt = valid.find(item => {
    return item ? 'false' : ''
  })
  return opt
}
export const getClassName = (child:any, valid: any[]) => {
  if (child.checkValue) {
    const val = child.checkValue()
    valid.push(val)
    return
  }
  child.$children.map((item:any) => {
    getClassName(item, valid)
  })
}
export const hasClass = (element:any, cls:any) => {
  return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1
}
export const validatorError = (parent:any) => {
  return validator(parent)
}
export const reset = (parent:any) => {
  parent.$children.map((child: { reset: () => void; }) => {
    if (child.reset) {
      child.reset()
    }
  })
}
