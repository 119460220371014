/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'moren': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M50 67c14.512 0 27.125 8.135 33.525 20.094C74.656 95.116 62.9 100 50 100c-12.9 0-24.658-4.885-33.526-12.905C22.875 75.135 35.488 67 50 67z" _fill="#D8D8D8"/>'
  }
})
