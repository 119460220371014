













import { Vue, Component, Prop } from 'vue-property-decorator'
import { AsDetailIterface } from '@/types/kyc'

@Component({
  name: 'moduleHeader'
})
export default class extends Vue {
  @Prop({ required: true }) private title!: string
  @Prop({ required: false }) private tagData!: AsDetailIterface
}
