











import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { CommonMudule } from '@/store/modules/common'

@Component({
  name: 'AppMain'
})
export default class extends Vue {
  get isLoading() {
    return AppModule.isLoading
  }
  created() {
    CommonMudule.LibraryAction()
  }
}
