import store from '@/store'
import { OptionList, getRMBRate, getImgSource, getAllocation } from '@/api/common'
import { formatResOption, getSessionAuth } from '@/utils/tools'
import { AuthItem } from '@/types/common'
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators'

export interface CommonState {
  nationData: object[]
  regionData: object[]
  occupationData: object[]
  curencyData: object[]
  customerId: string,
  customerName: string,
  authArr: string[]
}

export interface OptionInterface {
  type: string
  data: []
}

const seImgSource = JSON.parse(sessionStorage.getItem('imgSource') as string)

@Module({ name: 'common', dynamic: true, store })
class Common extends VuexModule implements CommonState {
  public customerId = sessionStorage.getItem('customerId') || ''
  public customerName = sessionStorage.getItem('customerName') || ''
  public nationData: object[] = []
  public regionData: object[] = []
  public occupationData: object[] = []
  public curencyData: object[] = []
  public rmbDollarRate = 0
  public imgSource = seImgSource && Object.keys(seImgSource).length > 0 ? seImgSource : {
    logo: [{
      url: ''
    }],
    pdf_childedu: [],
    pdf_cover: [],
    pdf_family: [],
    pdf_invest: [],
    pdf_retire: [],
    webicon: [
      {
        url: ''
      }
    ],
    wmguide: [],
  }
  public authArr:string[] = sessionStorage.getItem('authArr') ? JSON.parse(sessionStorage.getItem('authArr') as string) : []

  @Mutation SET_USER_NAME(v: string) {
    this.customerName = v
  }

  @Mutation SET_RDRate(v: number) {
    this.rmbDollarRate = v
  }

  @Mutation SET_IMG_SOURCE(v: any) {
    this.imgSource = v
    sessionStorage.setItem('imgSource', JSON.stringify(v))
  }

  @Mutation SET_ALLOW_DATA(v: AuthItem[]) {
    sessionStorage.setItem('auth', JSON.stringify(v))
    const temp = this.authArr
    v.forEach((item: AuthItem) => {
      if (item.off) {
        if (!temp.includes(item.allocationOffType)) {
          temp.push(item.allocationOffType)
        }
      }
    })
    this.authArr = temp
    sessionStorage.setItem('authArr', JSON.stringify(temp))
  }

  @Mutation SET_CUSTOMERID(v: any) {
    sessionStorage.setItem('customerId', v.id)
    sessionStorage.setItem('customerName', v.customerName)
    this.customerId = v.id
    this.customerName = v.customerName
  }
  @Mutation
  private SET_OPTION({ type, data }: OptionInterface) {
    if (type === 'Nation') {
      this.nationData = data
    }
    if (type === 'Region') {
      this.regionData = data
    }
    if (type === 'Occupation') {
      this.occupationData = data
    }
    if (type === 'Currency') {
      this.curencyData = formatResOption(data)
    }
  }

  @Action
  public async getAllocationAction() {
    const res = await getAllocation()
    if (res.data) {
      this.SET_ALLOW_DATA(res.data)
    }
  }

  @Action
  public async getRMBRateAction() {
    const res = await getRMBRate()
    if (res.data) { this.SET_RDRate(res.data) }
  }

  @Action
  public async getImgSourceAction() {
    const res = await getImgSource()
    if (res.data && Object.keys(res.data).length > 0) {
      this.SET_IMG_SOURCE(res.data)
    }
  }

  @Action
  public async LibraryAction() {
    const resArr = await Promise.all([
      OptionList({ dicType: 'Nation' }),
      OptionList({ dicType: 'Region' }),
      OptionList({ dicType: 'Occupation' }),
      OptionList({ dicType: 'Currency' })
    ])
    if (resArr[0].statusCode === 0) {
      const { data } = resArr[0]
      this.SET_OPTION({ type: 'Nation', data })
    }

    if (resArr[1].statusCode === 0) {
      const { data } = resArr[1]
      this.SET_OPTION({ type: 'Region', data })
    }

    if (resArr[2].statusCode === 0) {
      const { data } = resArr[2]
      this.SET_OPTION({ type: 'Occupation', data })
    }

    if (resArr[3].statusCode === 0) {
      const { data } = resArr[3]
      this.SET_OPTION({ type: 'Currency', data })
    }
  }
}

export const CommonMudule = getModule(Common)
