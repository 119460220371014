/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Insurance_product_admin': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13 0a2 2 0 012 2v12a2 2 0 01-2 2H5V0h8zM4 0v16H3a2 2 0 01-2-2V2a2 2 0 012-2h1zm9 2H8v7l2.5-2.23L13 9V2z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
