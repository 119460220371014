/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hover': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M88 105c0-20.987-17.013-38-38-38s-38 17.013-38 38 76 20.987 76 0z" _fill="#EDF4FF" mask="url(#mask-2)"/>'
  }
})
