






import { Component, Vue } from 'vue-property-decorator'
import { CommonMudule } from './store/modules/common'

@Component({
  name: 'App'
})
export default class extends Vue {
  created() {
    CommonMudule.getImgSourceAction()
    CommonMudule.getAllocationAction()
  }
}
